<template>
  <div>
    <ManageMegamillionsOrder />
  </div>
</template>
<script>
import ManageMegamillionsOrder from "@/components/Order/ManageMegamillionsOrder";
export default {
  components: {
    ManageMegamillionsOrder,
  },
  created() {
  },
};
</script>